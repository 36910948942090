import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";

const NewStudy = ({ setStudyId }) => {
  const navigate = useNavigate();

  const createNewStudy = (event) => {
    event.preventDefault();

    const studyDescription = event.target.studyDescription.value; // Get the input value

    apiRequest("study/", {
      method: "POST",
      body: JSON.stringify({ description: studyDescription }),
    })
      .then((response) => {
        if (response.status === 403) {
          toast.error("You don't have permission to create a study.");
        } else if (response.ok) {
          // Handle success
          toast.success("Study Successfully created!");
          return response.json();
        } else {
          // Handle other errors
          toast.error("An error occurred while creating the study.");
        }
      })
      .then((data) => {
        if (setStudyId) {
          setStudyId(data.id);
        } else {
          navigate("/studies");
        }
      })
      .catch((error) => {
        toast.error("An error occured while creating the study");
        console.error(error);
      });
  };

  return (
    <Stack gap={4} className="body">
      <h1>Create New Study</h1>
      <Form onSubmit={createNewStudy}>
        <Row>
          <Col as="h3" xs="auto">
            Study Details
          </Col>
          <Col xs={8}>
            <Form.Group controlId="studyDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control name="studyDescription" as="textarea" />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Button variant="secondary" type="submit">
          Submit
        </Button>
      </Form>
    </Stack>
  );
};

export default NewStudy;
