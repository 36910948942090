import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Stack, Table } from "react-bootstrap";

import { apiRequestPaginated } from "src/apiRequest";
import Pages from "src/components/Pages";
import { useUser } from "src/UserContext";

const CompaniesIndex = () => {
  const [isLoadingCompanies, setLoadingCompanies] = useState(true);
  const [companies, setCompanies] = useState([]);
  const { cerebraUser } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const itemsPerPage = 100;

  const getCompanies = () => {
    apiRequestPaginated(
      "company",
      {
        method: "GET",
      },
      currentPage,
      itemsPerPage,
    )
      .then((response) => {
        return response.json();
      })
      .then((d) => {
        const data = d.data;
        if (data.length < itemsPerPage) setIsLastPage(true);
        else setIsLastPage(false);
        setLoadingCompanies(false);
        if (data.length === 0) {
          setCurrentPage(Math.max(1, currentPage.current - 1));
        } else setCompanies(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getCompanies();
  }, [currentPage]);

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between">
        <h1>Companies</h1>
        {cerebraUser && cerebraUser.role === "SuperUser" && (
          <Button variant="outline-secondary" href="/newcompany">
            Create New Company <FontAwesomeIcon icon="plus" />
          </Button>
        )}
      </div>
      <div>
        <div className="data-card">
          <Table size="sm" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Description</th>
                <th>Date Created</th>
                <th>Active</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {!isLoadingCompanies &&
                companies.map((c) => (
                  <tr key={c.id}>
                    <td>{c.id}</td>
                    <td>{c.name}</td>
                    <td className="company-description-cell">
                      {c.description}
                    </td>
                    <td>{c.date_created}</td>
                    <td>{c.is_active ? "Yes" : "No"}</td>
                    <td>
                      <Button
                        variant="outline-primary"
                        href={`/company/${c.id}`}
                      >
                        Company Details
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Pages
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            isLastPage={isLastPage}
          />
        </div>
      </div>
    </Stack>
  );
};

export default CompaniesIndex;
