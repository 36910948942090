import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Stack, Table } from "react-bootstrap";

import { apiRequestPaginated } from "src/apiRequest";
import Pages from "src/components/Pages";
import { useUser } from "src/UserContext";

function UsersIndex() {
  const [isLoadingUsers, setLoadingUsers] = useState(true);
  const [users, setUsers] = useState([]);
  const { cerebraUser } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const itemsPerPage = 100;

  const getUsers = () => {
    apiRequestPaginated(
      "user",
      {
        method: "GET",
      },
      currentPage,
      itemsPerPage,
    )
      .then((response) => {
        return response.json();
      })
      .then((d) => {
        const data = d.data;
        if (data.length < itemsPerPage) setIsLastPage(true);
        else setIsLastPage(false);
        setLoadingUsers(false);
        if (data.length === 0) {
          setCurrentPage(Math.max(1, currentPage.current - 1));
        } else setUsers(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getUsers();
  }, [currentPage]);

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between">
        <h1>Users</h1>
        {cerebraUser && cerebraUser.role === "SuperUser" && (
          <Button variant="outline-secondary" href="/newuser">
            Create New User <FontAwesomeIcon icon="plus" />
          </Button>
        )}
      </div>
      <div>
        <div className="data-card">
          <Table size="sm" responsive>
            <thead>
              <tr>
                <th>User ID</th>
                <th>Email</th>
                <th>Role</th>
                <th>Site ID</th>
                <th>Active</th>
                <th>Date Created</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {!isLoadingUsers &&
                users.map((u) => (
                  <tr key={u.id}>
                    <td>{u.id}</td>
                    <td>{u.email}</td>
                    <td>{u.role}</td>
                    <td>{u.site_id}</td>
                    <td>{u.is_active ? "Yes" : "No"}</td>
                    <td>{u.date_created}</td>
                    <td>
                      <Button variant="outline-primary" href={`/user/${u.id}`}>
                        User Details
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Pages
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            isLastPage={isLastPage}
          />
        </div>
      </div>
    </Stack>
  );
}

export default UsersIndex;
