import { useEffect, useState } from "react";
import { Alert, Button, Col, Row, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import { apiRequest } from "src/apiRequest";
import PageLoading from "src/components/PageLoading";
import TextWithTooltip from "src/components/TextWithTooltip";

const OrpReport = ({ detailed = false }) => {
  const { scoringRunId } = useParams();
  const [scoringRun, setScoringRun] = useState();
  const [studyId, setStudyId] = useState();
  const [study, setStudy] = useState();
  const [orpData, setOrpData] = useState();
  const [isLoadingScoringRun, setIsLoadingScoringRun] = useState(true);
  const [isLoadingStudy, setIsLoadingStudy] = useState(true);
  const [isLoadingOrpData, setIsLoadingOrpData] = useState(true);

  const handlePrint = () => {
    const newTitle = `MY Sleep Scoring Study ${study.id} Scoringrun ${scoringRun.id}`;
    const originalTitle = document.title;
    document.title = newTitle;
    // Give a small timeout to ensure the title is changed before print dialog opens
    setTimeout(() => {
      window.print();
      document.title = originalTitle; // Revert back to the original title
    }, 100);
  };

  const getScoringRun = () => {
    apiRequest(`scoringrun/${scoringRunId}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setScoringRun(data);
        setIsLoadingScoringRun(false);
        setStudyId(data.files[0].study.id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getStudy = () => {
    apiRequest(`study/${studyId}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStudy(data);
        setIsLoadingStudy(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getOrpData = () => {
    let apiUrl = "";
    if (detailed) {
      apiUrl = `scoringrun/${scoringRun.id}/orpreportdetailed`;
    } else {
      apiUrl = `scoringrun/${scoringRun.id}/orpreportsimple`;
    }
    apiRequest(apiUrl, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setOrpData(data);
        setIsLoadingOrpData(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function prepareStagingHypnogramData() {
    const data = orpData["staging-30-sec"];
    const newData = [];

    // break out early if no staging data
    if (!data || data.length === 0) {
      return newData;
    }

    const startTime =
      orpData.edf_start_time && orpData.edf_start_time !== ""
        ? new Date(orpData.edf_start_time)
        : null;

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const epoch = parseInt(key);
        const value = data[key];
        let stageValue = 0;

        let epochTime = {};

        // setup the new new array with the properly formatted times to use for graphing
        if (startTime && startTime !== "") {
          epochTime = new Date(startTime.getTime() + (epoch - 1) * 30 * 1000);
        } else {
          epochTime = (parseInt(epoch) - 1) * 30;
        }

        // convert stage string to value to graph
        switch (value) {
          case "n3":
            stageValue = 0;
            break;
          case "n2":
            stageValue = 1;
            break;
          case "n1":
            stageValue = 2;
            break;
          case "rem":
            stageValue = 3;
            break;
          case "awake":
            stageValue = 4;
            break;
        }

        // actually push the values to the new data array
        newData.push({
          time: epochTime,
          stage: stageValue,
        });
      }
    }

    return newData;
  }

  const CustomLegendOrpHypnogram = () => {
    return (
      <ul className="bar-graph-legend">
        <li className="flex align-items-center">
          <svg width="10" height="7">
            <rect width="7" height="7" fill="url(#customGradient)" />
          </svg>
          <span>ORP</span>
        </li>
        {detailed && (
          <li className="flex align-items-center">
            <svg width="10" height="7">
              <rect width="7" height="7" fill="#8884d8" />
            </svg>
            <span>Stage</span>
          </li>
        )}
      </ul>
    );
  };

  function renderOrpEpochHypnogramGraph() {
    const orpDataEntries = orpData["ORP-30-sec"];
    const stagingData = prepareStagingHypnogramData();
    const newData = [];
    const startTime =
      orpData.edf_start_time && orpData.edf_start_time !== ""
        ? new Date(orpData.edf_start_time)
        : null;
    const customTicks = [];
    let conditionalProps = {};
    let firstTime = null;
    let lastTime = null;

    for (const key in orpDataEntries) {
      if (Object.prototype.hasOwnProperty.call(orpDataEntries, key)) {
        const epoch = parseInt(key);
        const value = orpDataEntries[key];

        let epochTime = {};

        // setup the new new array with the properly formatted times to use for graphing
        if (startTime && startTime !== "") {
          epochTime = new Date(startTime.getTime() + (epoch - 1) * 30 * 1000);
        } else {
          epochTime = (parseInt(epoch) - 1) * 30;
        }

        // actually push the values to the new data array
        newData.push({
          time: epochTime,
          value: value !== -1 ? value : null,
        });
      }
    }

    // Prepare your tick intervals for rendering
    if (startTime && startTime !== "") {
      const desiredInterval = 60 * 60 * 1000; // 1 hour in milliseconds
      firstTime = newData[0].time.getTime(); // Get the timestamp of the first time
      lastTime = newData[newData.length - 1].time.getTime(); // Get the timestamp of the last time

      for (let time = firstTime; time <= lastTime; time += desiredInterval) {
        customTicks.push(time);
      }
    } else {
      const desiredInterval = 60 * 60; // 1 hour in seconds
      firstTime = newData[0].time;
      lastTime = newData[newData.length - 1].time;

      for (let time = firstTime; time <= lastTime; time += desiredInterval) {
        customTicks.push(time);
      }
    }

    if (startTime && startTime !== "") {
      conditionalProps = {
        tickFormatter: (value) => {
          const date = new Date(value);
          return `${date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}`;
        },
      };
    } else {
      conditionalProps = {
        type: "number",
        domain: [0, newData[newData.length - 1].time],
      };
    }

    return (
      <div className="flex justify-content-center data-card orp-hypnogram-container">
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            width="100%"
            height={400}
            data={newData}
            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
          >
            <defs>
              <linearGradient
                id="customGradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#ef4e4f" />
                <stop offset="20%" stopColor="#f9a846" />
                <stop offset="40%" stopColor="#f6e148" />
                <stop offset="60%" stopColor="#a0f1a7" />
                <stop offset="80%" stopColor="#5bdcb7" />
                <stop offset="100%" stopColor="#3AA0F5" />
              </linearGradient>
            </defs>
            <XAxis
              label={{ value: "Time", position: "bottom", offset: 0 }}
              dataKey="time"
              ticks={customTicks}
              {...conditionalProps}
              tick={{
                fontSize: 15, // Specify the font size of the ticks
              }}
            />
            <YAxis
              label={{ value: "ORP", position: "center", offset: 0 }}
              domain={[0, 2.5]}
              ticks={[0, 0.5, 1, 1.5, 2, 2.5]}
            />
            {detailed && stagingData != null && (
              <YAxis
                type="number"
                domain={[-0.1, 4.1]}
                ticks={[0, 1, 2, 3, 4]}
                tickFormatter={(tick) => {
                  const stages = ["N3", "N2", "N1", "REM", "Awake"];
                  return stages[tick];
                }}
                orientation="right"
                yAxisId="right"
              />
            )}
            <Line
              type="monotone"
              dataKey="value"
              stroke="url(#customGradient)"
              dot={{ r: 0 }}
            />
            {detailed && stagingData != null && (
              <Line
                type="monotone"
                dataKey="stage"
                stroke="#8884d8"
                dot={false}
                yAxisId="right"
                data={stagingData}
              />
            )}
            <Legend
              height="25px"
              verticalAlign="top"
              content={<CustomLegendOrpHypnogram />}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  const customBarChartLabels = (props) => {
    const labels = [
      "Very Deep Sleep",
      "Deep Sleep",
      "Average Sleep",
      "Light Sleep",
      "",
      "Transtional State",
      "",
      "",
      "Drowsy Wake",
      "Full Wakefullness",
      "",
    ];
    const { x, y, width, index } = props;
    const radius = 10;
    const newX = x + width / 2;
    const newY = y - radius - 50;

    const shouldRotate = index !== 5;
    return (
      <text
        x={newX}
        y={newY}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
        transform={shouldRotate ? `rotate(-90, ${newX}, ${newY})` : undefined}
        style={{ fontSize: "15px" }}
      >
        {labels[index]}
      </text>
    );
  };

  function renderNormalBarGraph() {
    const graphData = [
      {
        range: "000_025",
        value: 10,
        fill: "#0d1e71",
      },
      {
        range: "025_050",
        value: 25,
        fill: "#0d1e71",
      },
      {
        range: "050_075",
        value: 25,
        fill: "#3aa0f5",
      },
      {
        range: "075_100",
        value: 15,
        fill: "#3aa0f5",
      },
      {
        range: "100_125",
        value: 10,
        fill: "#3aa0f5",
      },
      {
        range: "125_150",
        value: 5,
        fill: "#3aa0f5",
      },
      {
        range: "150_175",
        value: 2,
        fill: "#3aa0f5",
      },
      {
        range: "175_200",
        value: 2,
        fill: "#3aa0f5",
      },
      {
        range: "200_225",
        value: 5,
        fill: "#3aa0f5",
      },
      {
        range: "225_250",
        value: 7,
        fill: "#FF0000",
      },
      {
        range: "bad",
        value: 0,
        fill: "#3AA0F5",
      },
    ];

    const customTicks = [
      { value: "000_025", label: "1" },
      { value: "025_050", label: "2" },
      { value: "050_075", label: "3" },
      { value: "075_100", label: "4" },
      { value: "100_125", label: "5" },
      { value: "125_150", label: "6" },
      { value: "150_175", label: "7" },
      { value: "175_200", label: "8" },
      { value: "200_225", label: "9" },
      { value: "225_250", label: "10" },
      { value: "bad", label: "n/a" },
    ];

    return (
      <div className="flex justify-content-center">
        <BarChart
          width={600}
          height={400}
          data={graphData}
          className="decile-bar-chart"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="range"
            ticks={customTicks.map((tick) => tick.value)}
            tickFormatter={(tick) =>
              customTicks.find((t) => t.value === tick)?.label
            }
          />
          <YAxis
            label={{
              value: "% of sleep study",
              angle: -90,
              position: "insideLeft",
            }}
            ticks={[0, 5, 10, 15, 20, 25, 30, 35, 40]}
          />
          <Bar dataKey="value" fill={(data) => data.fill}>
            <LabelList
              dataKey="value"
              position="top"
              content={customBarChartLabels}
            />
          </Bar>
        </BarChart>
      </div>
    );
  }

  const getFillForRange = (range) => {
    if (range === "000_025" || range === "025_050") {
      return "#0d1e71";
    } else if (range === "225_250") {
      return "#FF0000";
    } else if (range === "bad") {
      return "#d3d3d3";
    } else {
      return "#3AA0F5";
    }
  };

  const CustomLegendOrpArchitecture = () => {
    const legendItems = [
      { value: "#FF0000", label: "ORP Fully Awake" },
      { value: "#0d1e71", label: "ORP Deep Sleep" },
      { value: "#d3d3d3", label: "Bad Data" },
    ];

    return (
      <ul className="bar-graph-legend">
        {legendItems.map((item, index) => (
          <li key={index} className="flex align-items-center">
            <svg width="10" height="7">
              <rect width="7" height="7" fill={item.value} />
            </svg>
            <span>{item.label}</span>
          </li>
        ))}
      </ul>
    );
  };

  function renderDecileBarGraph() {
    const preparedData = Object.keys(
      orpData.orp.summary.decile.total_study_percent,
    ).map((range) => ({
      range,
      value: orpData.orp.summary.decile.total_study_percent[range],
      fill: getFillForRange(range),
    }));

    preparedData.sort((a, b) => {
      if (a.range === "bad") return 1;
      if (b.range === "bad") return -1;
      return 0;
    });

    const customTicks = [
      { value: "000_025", label: "1" },
      { value: "025_050", label: "2" },
      { value: "050_075", label: "3" },
      { value: "075_100", label: "4" },
      { value: "100_125", label: "5" },
      { value: "125_150", label: "6" },
      { value: "150_175", label: "7" },
      { value: "175_200", label: "8" },
      { value: "200_225", label: "9" },
      { value: "225_250", label: "10" },
      { value: "bad", label: "n/a" },
    ];

    return (
      <div className="flex justify-content-center">
        <BarChart
          width={600}
          height={400}
          data={preparedData}
          className="decile-bar-chart"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="range"
            ticks={customTicks.map((tick) => tick.value)}
            tickFormatter={(tick) =>
              customTicks.find((t) => t.value === tick)?.label
            }
          />
          <YAxis
            label={{
              value: "% of sleep study",
              angle: -90,
              position: "insideLeft",
            }}
            ticks={[0, 5, 10, 15, 20, 25, 30, 35, 40]}
          />
          <Bar dataKey="value" fill={(data) => data.fill} />
          <Legend content={<CustomLegendOrpArchitecture />} />
        </BarChart>
      </div>
    );
  }

  const yesNoTranslate = (value) => {
    if (value) {
      return "Yes";
    } else {
      return "No";
    }
  };

  const detailedForDemonstrationOnly = () => {
    if (detailed) {
      return (
        <Alert variant="danger">
          DETAILED ORP REPORT FOR DEMONSTRATION PURPOSES ONLY
        </Alert>
      );
    }
  };

  useEffect(() => {
    getScoringRun();
  }, []);

  useEffect(() => {
    if (!isLoadingScoringRun) {
      // Only call getOrpData if scoringRun is set
      getOrpData();
    }
  }, [isLoadingScoringRun]);

  useEffect(() => {
    if (!isLoadingScoringRun && studyId) {
      // Only call getStudy if studyId is set
      getStudy();
    }
  }, [isLoadingScoringRun, studyId]);

  if (isLoadingScoringRun || isLoadingStudy || isLoadingOrpData) {
    return <PageLoading />;
  }

  return (
    <Stack gap={4} className="body">
      <Row>
        <Col>
          <h2>
            Study ID:{" "}
            <span className="header-value-highlight">{study?.id}</span>
          </h2>
        </Col>
        <Col className="text-end">
          <Button
            variant="outline-primary"
            className="btn hide-on-print"
            onClick={handlePrint}
          >
            Print
          </Button>
        </Col>
      </Row>
      <div className="data-card">
        <Row xs={1} lg={2} xxl={3}>
          <Col>
            <b>File ID: </b>
            {scoringRun?.files[0]?.id}
          </Col>
          <Col>
            <b>File Name: </b>
            {scoringRun?.files[0]?.name}
          </Col>
          <Col>
            <b>Description: </b>
            {study?.description}
          </Col>
          <Col>
            <b>File Description: </b>
            {scoringRun?.files[0]?.description}
          </Col>
          <Col>
            <b>Scoring Run ID: </b>
            {scoringRun?.id}
          </Col>
          <Col>
            <b>Lights Off: </b>
            {scoringRun?.lights_off_epoch}
          </Col>
          <Col>
            <b>Lights On: </b>
            {scoringRun?.lights_on_epoch}
          </Col>
          <Col>
            <b>AASM Prefiltered: </b>
            {yesNoTranslate(scoringRun.prefilter)}
          </Col>
          <Col>
            <b>Collection System: </b>
            {scoringRun?.collection_system}
          </Col>
          <Col>
            <b>Channel Mapping ID: </b>
            {scoringRun?.channelmapping?.id}
          </Col>
          <Col>
            <b>Channel Mapping Name: </b>
            {scoringRun?.channelmapping?.name}
          </Col>
          <Col>
            <b>Status: </b>
            {scoringRun?.status}
          </Col>
        </Row>
      </div>
      {detailedForDemonstrationOnly()}
      <h2 className="pagebreak">ORP Architecture:</h2>
      <Row className="g-4">
        <Col>
          <div className="data-card">
            <h4 style={{ textAlign: "center" }}>Normal ORP Architecture</h4>
            {renderNormalBarGraph()}
          </div>
        </Col>
        <Col>
          <div className="data-card">
            <h4 style={{ textAlign: "center" }}>Patient ORP Architecture</h4>
            {renderDecileBarGraph()}
          </div>
        </Col>
      </Row>
      <div className="data-card">
        <div className="flex flex-wrap">
          <Stack gap={2}>
            <Stack direction="horizontal" gap={3}>
              <div>
                <span className="data-label">Bin 1 & 2:</span>{" "}
                {(
                  orpData.orp?.summary?.decile?.total_study_percent["000_025"] +
                  orpData.orp?.summary?.decile?.total_study_percent["025_050"]
                )?.toFixed(1)}
                %
              </div>
              <div>
                <span className="data-label">Bin 10:</span>{" "}
                {orpData.orp?.summary?.decile?.total_study_percent[
                  "225_250"
                ]?.toFixed(1)}
                %
                {orpData?.orp_architecture?.split(",")[1] === "3" ? (
                  <span> Possible Hyperarousal</span>
                ) : (
                  ""
                )}
              </div>
            </Stack>
            <div>
              <span className="data-label">Notes:</span> The first two bins
              together (#1 and #2) indicate the percent of total recording time
              (TRT) in deep sleep. If less than 10.2% it is an indication of
              sleep disruption. The last bar (#10) indicates percent TRT in full
              wakefullness. If greater than 12.5 it may indicate hyperarousal.
            </div>
            <div>
              <span className="data-label">
                <TextWithTooltip
                  text="ORP Architecture:"
                  tooltip="A two digit representation, seperated by a comma, that provides a classification of sleep into 9 types.  The digits are derived from decile (bins) 1 & 2 representing very deep and deep sleep and decile (bin) 10 that represents fully awake."
                />
              </span>{" "}
              {orpData.orp_architecture}
            </div>
          </Stack>
        </div>
      </div>
      <h2 className="pagebreak">
        ORP Time Series:{" "}
        <span className="header-value-highlight">
          {orpData?.orp?.staging?.avg_orp?.all_stages?.toFixed(2)}
        </span>
      </h2>
      {renderOrpEpochHypnogramGraph()}
      {detailedForDemonstrationOnly()}
      {detailed && (
        <div className="data-card">
          <Row xs={2}>
            <Col>
              <table className="right-align-table-content">
                <tbody>
                  <tr>
                    <th scope="row">
                      <TextWithTooltip
                        text="ORP Average (Total Recording Time):"
                        tooltip="The average 30 second ORP value between lights off and lights on (if provided) or across the entire file duration."
                      />
                    </th>
                    <td>
                      {orpData?.orp?.staging?.avg_orp?.all_stages?.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <TextWithTooltip
                        text="*ORP Non-REM:"
                        tooltip="The average 30 second ORP value between lights off and lights on (if provided) or across the entire file duration for all sleep stages except REM."
                      />
                    </th>
                    <td>{orpData?.orp?.staging?.avg_orp?.nrem?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <TextWithTooltip
                        text="*ORP REM:"
                        tooltip="The average 30 second ORP value between lights off and lights on (if provided) or across the entire file duration for only REM sleep stages."
                      />
                    </th>
                    <td>{orpData?.orp?.staging?.avg_orp?.rem?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <TextWithTooltip
                        text="ORP-9 (Sleep Recovery):"
                        tooltip='Average 3 second ORP immediately after arousals (first 9 seconds).  A measure of a persons "arousability".  Higher values represent high arousable states.'
                      />
                    </th>
                    <td>
                      {orpData?.orp?.variables?.sleep_recovery?.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col>
              <table className="right-align-table-content">
                <tbody>
                  <tr>
                    <th scope="row">
                      <TextWithTooltip
                        text="AHI (Apnea-Hypopnea Index:"
                        tooltip="The number of apneas + number of hyponpneas identified per hour of sleep."
                      />
                    </th>
                    <td>
                      {orpData?.respiratory?.by_stage?.apneas_hypopneas?.total?.index?.toFixed(
                        2,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <TextWithTooltip
                        text="ODI (Oxygen Desaturation Index):"
                        tooltip="The number of times blood oxygen dropped by 2% per hour.  Measured between lights off and lites on (if provided) or across the entire file duration."
                      />
                    </th>
                    <td>
                      {orpData?.oxygen?.desaturations?.gt_3?.total?.index?.toFixed(
                        2,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <TextWithTooltip
                        text="PLM (Periodic Limb Movements Index):"
                        tooltip="The total number of arousals occuring during sleep only, and which are associated with a PLM event, per hour of sleep."
                      />
                    </th>
                    <td>{orpData?.legs?.plms?.total?.index?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <TextWithTooltip
                        text="Hypoxic-Burden (% min / hour):"
                        tooltip="The total area under the oxygen saturation curve from a pre-event basline oxygen desaturation."
                      />
                    </th>
                    <td>n/a</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      )}
      <div className="data-card pagebreak">
        <div>
          <span className="data-label">Disclaimer:</span>
          <ul style={{ listStyleType: "none" }}>
            <li>
              The values presented here are the result of autoscoring and have
              not undergone manual review. These values should not be used in
              isolation to make a diagnosis.
            </li>
            {detailed && (
              <li>
                * In a future release the information displayed in this section
                will be derived from input that contains manual scoring.
              </li>
            )}
          </ul>
        </div>
        <div>
          <span className="data-label">For more information:</span>
          <br />
          <ol style={{ listStyleType: "none" }}>
            <li>
              <b>ORP Architecture: </b>Younes, M., et al. (2022). Sleep
              architecture based on sleep depth and propensity: Patterns in
              different demographics and sleep disorders and association with
              health outcomes. Sleep, 45(6), zsac059.{" "}
              <a href="https://doi.org/10.1093/sleep/zsac059">
                https://doi.org/10.1093/sleep/zsac059
              </a>
            </li>
            <li>
              <b>ORP Values: </b>Younes, M., et al., (2015). Odds Ratio Product
              of sleep EEG as a continuous measure of sleep state. Sleep, 38(4),
              641-654.{" "}
              <a href="https://doi.org/10.5665/sleep.4588">
                https://doi.org/10.5665/sleep.4588
              </a>
            </li>
            <li>
              <b>ORP-9: </b>Younes, M., & Hanly, P.J. (2016). Immediate
              postarousal sleep dynamics: An important determinant of sleep
              stability in obstructive sleep apnea. Journal of Applied
              Physiology, 120, 801-808.{" "}
              <a href="https://doi.org/10.1152/japplphysiol.00880.2015">
                https://doi.org/10.1152/japplphysiol.00880.2015
              </a>
            </li>
            <li>
              <b>Notes: </b>Younes, M. (2023). New insights and potential
              clinical implications of the odds ratio product. Frontiers of
              Neurology, 14, 1273623.{" "}
              <a href="https://doi.org/10.3389/fneur.2023.1273623">
                https://doi.org/10.3389/fneur.2023.1273623
              </a>
            </li>
            <li>
              <b>Hypoxic Burden:</b> Azarbarzin, A., Sands, S. A., Stone, K. L.,
              Taranto-Montemurro, L., Messineo, L., Terrill, P. I.,
              Ancoli-Israel, S., Ensrud, K., Purcell, S., White, D. P., Redline,
              S., & Wellman, A. (2019). The hypoxic burden of sleep apnoea
              predicts cardiovascular disease-related mortality: the
              Osteoporotic Fractures in Men Study and the Sleep Heart Health
              Study. European heart journal, 40(14), 1149–1157.
              <a href="https://doi.org/10.1093/eurheartj/ehy624">
                https://doi.org/10.1093/eurheartj/ehy624
              </a>
            </li>
          </ol>
        </div>
      </div>
    </Stack>
  );
};

export default OrpReport;
