import { Table } from "react-bootstrap";

import { channelNames } from "src/components/ChannelMappings/names";

const ChannelMappingTable = ({ channelMapping }) => {
  return (
    <Table size="sm" responsive>
      <thead>
        <tr>
          <th>Channel Type</th>
          <th>EDF Channel Name</th>
          <th>EDF Channel Index</th>
        </tr>
      </thead>
      <tbody>
        {channelNames.map((cn) => {
          if (!channelMapping || !channelMapping[cn]) return null;
          return (
            <tr key={cn}>
              <td>{cn}</td>
              <td>{channelMapping[cn].channel_name}</td>
              <td>{channelMapping[cn].channel_index}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ChannelMappingTable;
