import { getConfig } from "src/config";
import { auth } from "src/firebase";

const config = getConfig();

export const apiRequest = async (endpoint, data) => {
  return auth.currentUser.getIdToken().then((idToken) => {
    return fetch(`${config.apiHostname}/api/${endpoint}`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${idToken}`,
      },
      ...data,
    });
  });
};

export const apiRequestPaginated = async (
  endpoint,
  data,
  currentPage,
  itemsPerPage,
  urlSearchParams = {},
  sortOrder = "desc",
) => {
  const queryParams = new URLSearchParams({
    skip: (currentPage - 1) * itemsPerPage,
    limit: itemsPerPage,
    sort_order: sortOrder,
    ...urlSearchParams,
  }).toString();
  return apiRequest(`${endpoint}?${queryParams}`, data);
};
