export const channelNames = [
  "eeg_left",
  "eeg_right",
  "eye_left",
  "eye_right",
  "mastoid",
  "chin",
  "ekg",
  "leg_left",
  "leg_right",
  "resp_cannula",
  "resp_chest",
  "resp_abdomen",
  "oxygen_sat",
  "pulse",
  "audio",
  "resp_thermistor",
  "cpapflow",
  "epap",
  "ipap",
  "etc02",
  "transc02",
  "position",
];

export const positionNames = [
  "unknown",
  "supine",
  "prone",
  "left",
  "right",
  "upright",
];
