import { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

const markdownFilesContext = require.context(
  "src/components/Regulatory/ReleaseNotesMarkdown",
);

function ReleaseNotes() {
  const [markdownByFile, setMarkdownByFile] = useState({});

  useEffect(() => {
    markdownFilesContext.keys().forEach((file) => {
      fetch(markdownFilesContext(file))
        .then((response) => response.text())
        .then((markdown) => {
          setMarkdownByFile((prevState) => ({
            ...prevState,
            [file]: markdown,
          }));
        });
    });
  }, []);

  return (
    <Stack gap={4} className="body">
      <h1>Release Notes</h1>
      <div className="button-row">
        <Button variant="outline-primary" href="/softwarelabel">
          Software Label
        </Button>
        <Button
          variant="outline-primary"
          href="https://www.cerebra.health/privacy-policy-2/"
        >
          Privacy Policy
        </Button>
      </div>
      {Object.entries(markdownByFile)
        .sort()
        .reverse()
        .map(([file, markdown]) => (
          <div className="data-card" key={file}>
            <ReactMarkdown>{markdown}</ReactMarkdown>
          </div>
        ))}
    </Stack>
  );
}

export default ReleaseNotes;
