import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Stack, Table } from "react-bootstrap";

import { apiRequest, apiRequestPaginated } from "src/apiRequest";
import Pages from "src/components/Pages";
import { useUser } from "src/UserContext";

const SitesIndex = () => {
  const [isLoadingSites, setLoadingSites] = useState(true);
  const [sites, setSites] = useState([]);
  const [companyNames, setCompanyNames] = useState({});
  const { cerebraUser } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const itemsPerPage = 100;

  const getSites = () => {
    apiRequestPaginated(
      "site",
      {
        method: "GET",
      },
      currentPage,
      itemsPerPage,
    )
      .then((response) => response.json())
      .then((d) => {
        const data = d.data;
        if (data.length < itemsPerPage) setIsLastPage(true);
        else setIsLastPage(false);
        setLoadingSites(false);
        if (data.length === 0) {
          setCurrentPage(Math.max(1, currentPage.current - 1));
        } else setSites(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getCompanyName = (companyId) => {
    return apiRequest(`company/${companyId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => data.name)
      .catch((error) => {
        console.error(error);
        return ""; // Return an empty string if there's an error
      });
  };

  useEffect(() => {
    getSites();
  }, [currentPage]);

  useEffect(() => {
    if (!isLoadingSites) {
      // Fetch company names for all sites
      Promise.all(sites.map((s) => getCompanyName(s.company_id)))
        .then((companyNames) => {
          const companyNameMap = {};
          sites.forEach((site, index) => {
            companyNameMap[site.id] = companyNames[index];
          });
          setCompanyNames(companyNameMap);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isLoadingSites, sites]);

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between">
        <h1>Sites</h1>
        {cerebraUser && cerebraUser.role === "SuperUser" && (
          <Button variant="outline-secondary" href="/newsite">
            Create New Site <FontAwesomeIcon icon="plus" />
          </Button>
        )}
      </div>
      <div>
        <div className="data-card">
          <Table size="sm" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Company Name</th>
                <th>Description</th>
                <th>Date Created</th>
                <th>Active</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {!isLoadingSites &&
                sites.map((s) => (
                  <tr key={s.id}>
                    <td>{s.id}</td>
                    <td>{s.name}</td>
                    <td>{companyNames[s.id] || ""}</td>
                    <td>{s.description}</td>
                    <td>{s.date_created}</td>
                    <td>{s.is_active ? "Yes" : "No"}</td>
                    <td>
                      <Button variant="outline-primary" href={`/site/${s.id}`}>
                        Site Details
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Pages
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            isLastPage={isLastPage}
          />
        </div>
      </div>
    </Stack>
  );
};

export default SitesIndex;
