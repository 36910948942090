import { useState } from "react";
import { Button, Card, Container } from "react-bootstrap";

import { apiRequest } from "src/apiRequest";

function EpochLookup() {
  const [epochData, setEpochData] = useState([]);
  const [studyId, setStudyId] = useState(-1);
  const [isLoadingFileUpload, setLoadingFileUpload] = useState(true);
  const [currentEpochIndex, setCurrentEpochIndex] = useState(0);
  const [currentDisplayedEpoch, setCurrentDisplayedEpoch] = useState({
    DeltaDurations: {
      EEG1: "N/A",
      EEG2: "N/A",
    },
    ORPAverage: {
      combined_orp_average: "N/A",
      orp1_average: "N/A",
      orp2_average: "N/A",
    },
  });
  const getEpochLookup = (
    studyId,
    startEpoch = 0,
    endEpoch = startEpoch + 50,
  ) => {
    // TODO - right now we are lazy, and we will just load them in batches of 50, and not "keep" previous results
    // in the future - we should keep saving the results from the previous calls incase they ever go back to them
    // simple boundary for not going below zero
    if (startEpoch < 0) {
      startEpoch = 0;
    }

    // load the epochs from start_epoch to end_epoch.  defaults to loading 0 to 50 on the first load
    const epochList = Array.from(
      { length: endEpoch - startEpoch },
      (x, i) => startEpoch + i,
    );

    apiRequest(`fileupload/${studyId}/epoch_lookup/`, {
      method: "POST",
      body: JSON.stringify({
        epoch_list: epochList,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setEpochData(data);
        setLoadingFileUpload(false);
        displayEpoch();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // update state with the study ID we want to load when they type
  const fileUploadIDChange = (e) => {
    setStudyId(e.target.value);
  };

  // load the initial epoch data for the file when a customer clicks "load file"
  const loadFileUpload = () => {
    if (studyId !== -1) {
      getEpochLookup(studyId, currentEpochIndex);
    }
  };

  const previousEpoch = () => {
    if (currentEpochIndex === 0) {
      return;
    }

    setCurrentEpochIndex(currentEpochIndex - 1);
    const missingData = displayEpoch();

    if (missingData) {
      getEpochLookup(studyId, currentEpochIndex - 50, currentEpochIndex + 50);
    }
  };

  const nextEpoch = () => {
    setCurrentEpochIndex(currentEpochIndex + 1);
    const missingData = displayEpoch();

    if (missingData) {
      getEpochLookup(studyId, currentEpochIndex - 50, currentEpochIndex + 50);
    }
  };

  const displayEpoch = () => {
    if (epochData) {
      if (currentEpochIndex in epochData) {
        setCurrentDisplayedEpoch({
          DeltaDurations: {
            EEG1: epochData[currentEpochIndex].DeltaDurations.EEG1,
            EEG2: epochData[currentEpochIndex].DeltaDurations.EEG2,
          },
          ORPAverage: {
            combined_orp_average:
              epochData[currentEpochIndex].ORPAverage.combined_orp_average,
            orp1_average: epochData[currentEpochIndex].ORPAverage.orp1_average,
            orp2_average: epochData[currentEpochIndex].ORPAverage.orp2_average,
          },
        });

        // return false if there is no missing data
        return false;
      }
    }

    // return false if there is no missing data
    return true;
  };

  return (
    <Container className="p-3">
      <form>
        <label>
          Fileupload ID:
          <input type="text" name="name" onChange={fileUploadIDChange} />
        </label>
        <Button onClick={loadFileUpload}>Load</Button>
      </form>
      <Container className="p-3">
        <Card border="primary" style={{ width: "18rem" }}>
          <Card.Header>FileUpload ID: </Card.Header>
          <Card.Body>
            <Card.Text>
              <div>
                <h3>Delta Durations</h3>
                EEG1: {currentDisplayedEpoch.DeltaDurations.EEG1}
                <br />
                EEG2: {currentDisplayedEpoch.DeltaDurations.EEG2}
              </div>
              <div>
                <h3>ORP Average</h3>
                combined_orp_average:{" "}
                {currentDisplayedEpoch.ORPAverage.combined_orp_average}
                <br />
                orp1_average: {currentDisplayedEpoch.ORPAverage.orp1_average}
                <br />
                orp2_average: {currentDisplayedEpoch.ORPAverage.orp2_average}
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <br />
      </Container>
      <Button
        variant="secondary"
        disabled={isLoadingFileUpload}
        onClick={previousEpoch}
      >
        {" "}
        Previous
      </Button>
      <label id="current_epoch_label">Epoch: {currentEpochIndex}</label>
      <Button
        variant="secondary"
        disabled={isLoadingFileUpload}
        onClick={nextEpoch}
      >
        {" "}
        Next
      </Button>
    </Container>
  );
}

export default EpochLookup;
