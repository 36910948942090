import { useEffect, useState } from "react";
import { Col, Dropdown, Stack, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { apiRequest } from "src/apiRequest";
import PageLoading from "src/components/PageLoading";

const UsersShow = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState();
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  const getUserData = () => {
    apiRequest(`user/${id}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUserData(data);
        setIsLoadingUserData(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  if (isLoadingUserData) {
    return <PageLoading />;
  }

  if (!isLoadingUserData && !userData) {
    return <div>User not found</div>;
  }

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between me-5">
        <h1>User ID: {userData.id}</h1>

        <Dropdown>
          <Dropdown.Toggle variant="outline-primary">Actions</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={`/editUser/${id}`}>Edit</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="flex flex-wrap justify-content-between align-items-start">
        <Col xs={12} className="data-card">
          <Table size="sm" borderless>
            <tbody>
              <tr>
                <th scope="row">Email:</th>
                <td>{userData.email}</td>
              </tr>
              <tr>
                <th scope="row">Role:</th>
                <td>{userData.role}</td>
              </tr>
              <tr>
                <th scope="row">Site ID:</th>
                <td>{userData.site_id}</td>
              </tr>
              <tr>
                <th scope="row">Active:</th>
                <td>{userData.is_active ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th scope="row">Created At:</th>
                <td>{userData.date_created}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </div>
    </Stack>
  );
};

export default UsersShow;
