import { Col, Form, Table } from "react-bootstrap";

import { positionNames } from "src/components/ChannelMappings/names";

const PositionTable = ({ channelMapping, form = false }) => {
  return (
    <Table size="sm" responsive>
      <thead>
        <tr>
          <Col as="th" xs={4} md={3} lg={2}>
            Position Name
          </Col>
          <Col as="th">Position Value</Col>
        </tr>
      </thead>
      <tbody>
        {positionNames.map((pn) => {
          const key = `${pn}_value`;
          if (!channelMapping?.position?.[key] && !form) return null;
          return (
            <tr key={pn}>
              <Col as="td" xs={4} md={3} lg={2}>
                Position - {pn}
              </Col>
              <Col as="td">
                {form ? (
                  <Form.Group controlId={`${pn}Position`}>
                    <Form.Control type="text" name={`${pn}Position`} />
                  </Form.Group>
                ) : (
                  channelMapping.position[key]
                )}
              </Col>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default PositionTable;
