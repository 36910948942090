import { Spinner } from "react-bootstrap";

const PageLoading = () => {
  return (
    <div className="body d-flex justify-content-center">
      <Spinner role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default PageLoading;
