import { multiFactor } from "firebase/auth";
import { useState } from "react";
import { Badge, Button, Col, Form, Row, Stack } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";

import VerificationCodeModal from "src/components/VerificationCodeModal";
import { enrollMfa, unenrollMfa, verifyEmail } from "src/firebase";
import { useUser } from "src/UserContext";

function Profile() {
  const { firebaseUser } = useUser();
  const [showAccessToken, setShowAccessToken] = useState(false);
  const [showRefreshToken, setShowRefreshToken] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [verificationCallback, setVerificationCallback] = useState(null);

  const handleEnroll = (event) => {
    event.preventDefault();
    enrollMfa(phoneNumber, (callback) => {
      setVerificationCallback(() => callback);
      setShowVerify(true);
    });
  };

  const handleVerify = (code) => {
    if (verificationCallback) {
      verificationCallback(code);
      setVerificationCallback(null);
    }
    setShowVerify(false);
  };

  const copyAccessToken = () => {
    navigator.clipboard.writeText(firebaseUser?.accessToken);
    toast.info("Copied Access Token to Clipboard!");
  };

  const copyRefreshToken = () => {
    navigator.clipboard.writeText(firebaseUser?.refreshToken);
    toast.info("Copied Refresh Token to Clipboard!");
  };

  const toggleAccessToken = () => {
    setShowAccessToken(!showAccessToken);
  };

  const toggleRefreshToken = () => {
    setShowRefreshToken(!showRefreshToken);
  };

  return (
    <Stack gap={4} className="body">
      <h1>Profile</h1>
      <Row xs={1} md={2} xxl={4} className="g-4">
        <Col>
          <div className="data-card h-100">
            <Stack gap={2}>
              <h2>User</h2>
              <div>UID: {firebaseUser?.uid}</div>
              <div>
                Email: {firebaseUser?.email}{" "}
                {firebaseUser?.emailVerified && (
                  <Badge bg="success">VERIFIED</Badge>
                )}
              </div>
              {!firebaseUser?.emailVerified && (
                <Button onClick={verifyEmail}>
                  Send Email Verification Link
                </Button>
              )}
            </Stack>
          </div>
        </Col>
        <Col>
          <div className="data-card h-100">
            <Stack gap={2}>
              <h2>Multi-Factor Authentication</h2>
              {firebaseUser?.emailVerified ? (
                multiFactor(firebaseUser).enrolledFactors.length > 0 ? (
                  <Button onClick={unenrollMfa}>Unenroll</Button>
                ) : (
                  <Form onSubmit={handleEnroll}>
                    <Stack gap={2}>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        defaultCountry="CA"
                      />
                      <Button type="submit">Enroll</Button>
                    </Stack>
                  </Form>
                )
              ) : (
                "Email must first be verified to enroll in MFA"
              )}
            </Stack>
          </div>
        </Col>
        <Col>
          <div className="data-card h-100">
            <Stack gap={2}>
              <h2>Access Token</h2>
              <div className="button-row">
                <Button onClick={copyAccessToken}>Copy</Button>
                <Button onClick={toggleAccessToken}>
                  {showAccessToken ? "Hide" : "Show"}
                </Button>
              </div>
              {showAccessToken && (
                <div className="tokens">{firebaseUser?.accessToken} </div>
              )}
            </Stack>
          </div>
        </Col>
        <Col>
          <div className="data-card h-100">
            <Stack gap={2}>
              <h2>Refresh Token</h2>
              <div className="button-row">
                <Button onClick={copyRefreshToken}>Copy</Button>
                <Button onClick={toggleRefreshToken}>
                  {showRefreshToken ? "Hide" : "Show"}
                </Button>
              </div>
              {showRefreshToken && (
                <div className="tokens">{firebaseUser?.refreshToken}</div>
              )}
            </Stack>
          </div>
        </Col>
      </Row>
      <VerificationCodeModal show={showVerify} handleVerify={handleVerify} />
    </Stack>
  );
}

export default Profile;
