import { useEffect, useState } from "react";
import { Nav, Stack } from "react-bootstrap";

import "src/assets/Wizard.scss";
import SuggestChannelMapping from "src/components/ChannelMappings/Suggest";
import DragDropFile from "src/components/DragDropFile";
import NewStudy from "src/components/Studies/Create";
import UpdateScoringSettings from "src/components/Studies/UpdateScoringSettings";

function Wizard() {
  const [activeKey, setActiveKey] = useState("creation");
  const [studyId, setStudyId] = useState(null);
  const [scoringRunId, setScoringRunId] = useState(null);
  const [scoringSettingsUpdated, setScoringSettingsUpdated] = useState(false);

  useEffect(() => {
    if (studyId) setActiveKey("upload");
  }, [studyId]);

  useEffect(() => {
    if (scoringRunId) setActiveKey("settings");
  }, [scoringRunId]);

  useEffect(() => {
    if (scoringSettingsUpdated) setActiveKey("mapping");
  }, [scoringSettingsUpdated]);

  return (
    <div className="d-flex flex-nowrap flex-grow-1">
      <Nav
        variant="pills"
        activeKey={activeKey}
        className="d-flex flex-column p-3"
        onSelect={(eventKey) => setActiveKey(eventKey)}
      >
        <Nav.Link eventKey="creation" disabled={studyId}>
          {studyId ? `Study ID: ${studyId}` : "Study Creation"}
        </Nav.Link>
        <Nav.Link eventKey="upload" disabled={!studyId || scoringRunId}>
          {scoringRunId ? `Scoring Run ID: ${scoringRunId}` : "File Upload"}
        </Nav.Link>
        <Nav.Link eventKey="settings" disabled={!scoringRunId}>
          Scoring Settings
        </Nav.Link>
        <Nav.Link eventKey="mapping" disabled={!scoringRunId}>
          Channel Mapping
        </Nav.Link>
      </Nav>
      <div className="flex-grow-1">
        <div style={{ display: activeKey === "creation" ? "block" : "none" }}>
          <NewStudy setStudyId={setStudyId} />
        </div>
        <div style={{ display: activeKey === "upload" ? "block" : "none" }}>
          <Stack gap={4} className="body">
            <h1>File Upload</h1>
            <DragDropFile studyId={studyId} onUploadSuccess={setScoringRunId} />
          </Stack>
        </div>
        <div style={{ display: activeKey === "settings" ? "block" : "none" }}>
          <UpdateScoringSettings
            id={scoringRunId}
            setScoringSettingsUpdated={setScoringSettingsUpdated}
          />
        </div>
        <div style={{ display: activeKey === "mapping" ? "block" : "none" }}>
          <SuggestChannelMapping id={scoringRunId} />
        </div>
      </div>
    </div>
  );
}

export default Wizard;
