import { useEffect, useRef, useState } from "react";
import { Col, Row, Stack, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { apiRequest } from "src/apiRequest";
import DragDropFile from "src/components/DragDropFile";
import PageLoading from "src/components/PageLoading";
import ScoringRunTable from "src/components/ScoringRuns";

const StudyDetails = () => {
  const { id } = useParams();
  const [study, setStudy] = useState();
  const [createdBy, setCreatedBy] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const tableRef = useRef();

  const getStudy = () => {
    return apiRequest(`study/${id}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error(response.status, response.statusText);
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  };

  const getCreatedBy = () => {
    return apiRequest(`user/${study.user_id}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error(response.status, response.statusText);
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  };

  useEffect(() => {
    // Fetch study information
    getStudy().then((studyData) => {
      // Check if the study data is different from the current study state
      if (studyData && JSON.stringify(studyData) !== JSON.stringify(study)) {
        setStudy(studyData);
      }
    });
  }, []);

  useEffect(() => {
    if (!study) return;

    getCreatedBy()
      .then((userData) => {
        setCreatedBy(userData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [study]);

  if (isLoading) {
    return <PageLoading />;
  }

  if (!isLoading && !study) {
    return <div>Study not found</div>;
  }

  return (
    <Stack gap={4} className="body">
      <h1>Study ID: {study.id}</h1>
      <Row xs={2}>
        <Col>
          <div className="data-card h-100">
            <Table size="sm" borderless>
              <tbody>
                <tr>
                  <th scope="row">Description:</th>
                  <td>{study.description}</td>
                </tr>
                <tr>
                  <th scope="row">Created At:</th>
                  <td>{study.date_created}</td>
                </tr>
                <tr>
                  <th scope="row">Created By:</th>
                  <td>{createdBy.email}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
        <Col>
          <div className="data-card d-flex justify-content-center">
            <DragDropFile
              studyId={study.id}
              onUploadSuccess={() => tableRef.current.refreshData()}
            />
          </div>
        </Col>
      </Row>
      <ScoringRunTable studyId={study.id} ref={tableRef} />
    </Stack>
  );
};

export default StudyDetails;
