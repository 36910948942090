import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import App from "src/App";
import "src/assets/index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
