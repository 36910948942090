import { useEffect, useState } from "react";
import { Col, Row, Stack, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { apiRequest } from "src/apiRequest";
import ChannelMappingTable from "src/components/ChannelMappings/ChannelMappingTable";
import PositionTable from "src/components/ChannelMappings/PositionTable";
import PageLoading from "src/components/PageLoading";

const ChannelMappingDetails = () => {
  const { id } = useParams();
  const [channelMapping, setChannelMapping] = useState();
  const [isLoadingChannelMapping, setIsLoadingChannleMapping] = useState(true);

  const getChannelMapping = () => {
    apiRequest(`channelmapping/${id}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setChannelMapping(data);
        setIsLoadingChannleMapping(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getChannelMapping();
  }, []);

  if (isLoadingChannelMapping) {
    return <PageLoading />;
  }

  if (!isLoadingChannelMapping && !channelMapping) {
    return <div>Study not found</div>;
  }

  return (
    <Stack gap={4} className="body">
      <h1>Channel Mapping ID: {channelMapping.id}</h1>
      <Col xs={5} className="data-card">
        <Table size="sm" borderless>
          <tbody>
            <tr>
              <th scope="row">Name:</th>
              <td>{channelMapping.name}</td>
            </tr>
            <tr>
              <th scope="row">User Id:</th>
              <td>{channelMapping.user_id}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Row
        xs={1}
        lg={2}
        gap={4}
        direction="horizontal"
        className="align-items-start"
      >
        <Col>
          <h2>Channels</h2>
          <div className="data-card">
            <ChannelMappingTable channelMapping={channelMapping} />
          </div>
        </Col>
        <Col>
          <h2>Positions</h2>
          <div className="data-card">
            <PositionTable channelMapping={channelMapping} />
          </div>
        </Col>
      </Row>
    </Stack>
  );
};

export default ChannelMappingDetails;
