import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  getMultiFactorResolver,
  GoogleAuthProvider,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
} from "firebase/auth";
import { toast } from "react-toastify";

import { getConfig } from "src/config";

const config = getConfig();

const firebaseConfig = {
  apiKey: config.apiKey,
  authDomain: config.authDomain,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.addScope("profile");
googleProvider.addScope("email");

const displayError = (err) => {
  console.error(err);
  switch (err.code) {
    case "auth/captcha-check-failed":
      // Do nothing here because the recaptcha is invisible
      break;
    case "auth/requires-recent-login":
      toast.error(
        "This operation is sensitive and requires recent authentication. Log out and log in again before retrying this request.",
      );
      break;
    default:
      toast.error(err.message);
      break;
  }
};

const signInWithGoogle = () => {
  signInWithPopup(auth, googleProvider).catch((err) => displayError(err));
};

const registerWithEmailAndPassword = (name, email, password) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((res) => res.user)
    // this sets the display name after the user is created
    .then((user) => updateProfile(user, { displayName: name }))
    .catch((err) => displayError(err));
};

const createUser = (email, password) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then(() => toast.success("User successfully created"))
    .catch((err) => displayError(err));
};

const loginWithEmailAndPassword = (email, password, showVerificationModal) => {
  signInWithEmailAndPassword(auth, email, password)
    .catch((error) => {
      if (error.code === "auth/multi-factor-auth-required") {
        const resolver = getMultiFactorResolver(auth, error);
        return new PhoneAuthProvider(auth)
          .verifyPhoneNumber(
            {
              multiFactorHint: resolver.hints[0], // only allowing one phone
              session: resolver.session,
            },
            recaptchaVerifier,
          )
          .then(
            (verificationId) =>
              new Promise((resolve) =>
                showVerificationModal((code) =>
                  resolve(PhoneAuthProvider.credential(verificationId, code)),
                ),
              ),
          )
          .then((cred) => PhoneMultiFactorGenerator.assertion(cred))
          .then((multiFactorAssertion) =>
            resolver.resolveSignIn(multiFactorAssertion),
          );
      } else {
        throw error;
      }
    })
    .catch((err) => displayError(err));
};

const sendPasswordReset = (email) => {
  sendPasswordResetEmail(auth, email)
    .then(() =>
      toast.success("Password reset link sent! Check your spam folder."),
    )
    .catch((err) => displayError(err));
};

const logout = () => {
  signOut(auth);
};

const verifyEmail = () => {
  sendEmailVerification(auth.currentUser)
    .then(() => toast.success("Verification email sent!"))
    .catch((err) => displayError(err));
};

const enrollMfa = (phoneNumber, showVerificationModal) => {
  const mfu = multiFactor(auth.currentUser);
  mfu
    .getSession()
    .then((multiFactorSession) =>
      new PhoneAuthProvider(auth).verifyPhoneNumber(
        {
          phoneNumber,
          session: multiFactorSession,
        },
        recaptchaVerifier,
      ),
    )
    .then(
      (verificationId) =>
        new Promise((resolve) =>
          showVerificationModal((code) =>
            resolve(PhoneAuthProvider.credential(verificationId, code)),
          ),
        ),
    )
    .then((cred) => PhoneMultiFactorGenerator.assertion(cred))
    .then((multiFactorAssertion) => mfu.enroll(multiFactorAssertion))
    .then(() => toast.success("MFA successfully enrolled"))
    .catch((err) => displayError(err));
};

const unenrollMfa = () => {
  const mfu = multiFactor(auth.currentUser);
  mfu
    .unenroll(mfu.enrolledFactors[0]) // only allowing one phone
    .then(() => toast.success("MFA successfully unenrolled"))
    .catch((err) => displayError(err));
};

const recaptchaVerifier = new RecaptchaVerifier(
  "recaptcha-container-id",
  { size: "invisible" },
  auth,
);

export {
  auth,
  signInWithGoogle,
  registerWithEmailAndPassword,
  createUser,
  loginWithEmailAndPassword,
  sendPasswordReset,
  logout,
  verifyEmail,
  enrollMfa,
  unenrollMfa,
};
