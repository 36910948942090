import { Pagination } from "react-bootstrap";

const Pages = ({ setCurrentPage, currentPage, isLastPage }) => {
  return (
    <div className="d-flex justify-content-center">
      <Pagination>
        <Pagination.First
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
        />
        <Pagination.Prev
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
        <Pagination.Next
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={isLastPage}
        />
      </Pagination>
    </div>
  );
};

export default Pages;
