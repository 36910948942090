import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";

const CompaniesCreate = () => {
  const navigate = useNavigate();

  const createNewCompany = (event) => {
    event.preventDefault();

    const companyName = event.target.companyName.value;
    const companyDescription = event.target.companyDescription.value;

    apiRequest("company/", {
      method: "POST",
      body: JSON.stringify({
        name: companyName,
        description: companyDescription,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          toast.error("You don't have permission to create a company.");
        } else if (response.ok) {
          // Handle success
          toast.success("Company Successfully created!");
          navigate("/companies");
        } else {
          // Handle other errors
          toast.error("An error occurred while creating the company.");
        }
      })
      .catch((error) => {
        toast.error("An error occured while creating the company");
        console.error(error);
      });
  };

  return (
    <Stack gap={4} className="body">
      <h1>Create New Company</h1>
      <Form onSubmit={createNewCompany}>
        <Row>
          <Col as="h3" xs="auto">
            Company Details
          </Col>
          <Col xs={9}>
            <Stack gap={3}>
              <Form.Group controlId="companyName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="companyName" />
              </Form.Group>
              <Form.Group controlId="companyDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="companyDescription"
                  as="textarea"
                />
              </Form.Group>
            </Stack>
          </Col>
        </Row>
        <hr />
        <Button variant="secondary" type="submit">
          Submit
        </Button>
      </Form>
    </Stack>
  );
};

export default CompaniesCreate;
