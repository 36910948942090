import { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import "src/assets/App.scss";
import Login from "src/components/Login";
import NavBar from "src/components/NavBar";
import PageLoading from "src/components/PageLoading";
import { logout } from "src/firebase";
import { useUser } from "src/UserContext";

function Root() {
  const { firebaseUser, userLoading } = useUser();
  const [logoutMessage, setLogoutMessage] = useState("");

  const handleLogout = useCallback(() => {
    setLogoutMessage(
      "You were inactive for 30 minutes. The MY ORP system automatically logged you out to protect patient privacy. This is a HIPPA requirement.",
    );
    logout();
  }, []);

  const resetTimer = useCallback(() => {
    clearTimeout(window.inactivityTimeout);
    // window.inactivityTimeout = setTimeout(handleLogout, 30000); // 30 seconds - for testing
    window.inactivityTimeout = setTimeout(handleLogout, 30 * 60 * 1000); // 30 minutes
  }, [handleLogout]);

  useEffect(() => {
    if (firebaseUser) {
      window.addEventListener("mousemove", resetTimer);
      window.addEventListener("click", resetTimer);
      window.addEventListener("keypress", resetTimer);

      resetTimer();

      return () => {
        clearTimeout(window.inactivityTimeout);
        window.removeEventListener("mousemove", resetTimer);
        window.removeEventListener("click", resetTimer);
        window.removeEventListener("keypress", resetTimer);
      };
    }
  }, [firebaseUser, resetTimer]);

  const component = userLoading ? (
    <PageLoading />
  ) : firebaseUser ? (
    <Outlet />
  ) : (
    <Login logoutMessage={logoutMessage} />
  );

  return (
    <>
      <NavBar />
      {component}
    </>
  );
}

export default Root;
