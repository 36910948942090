import { Button, Col, Form, Stack, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";
import {
  channelNames,
  positionNames,
} from "src/components/ChannelMappings/names";
import PositionTable from "src/components/ChannelMappings/PositionTable";

const NewChannelMapping = () => {
  const navigate = useNavigate();

  const createNewChannelMapping = (event) => {
    event.preventDefault();

    const channelMappingName = event.target.channelMappingName.value;

    const channelIndexes = channelNames.map(
      (cn) => event.target[`${cn}_channel_index`].value,
    );

    const positions = positionNames.map(
      (pn) => event.target[`${pn}Position`].value,
    );

    function filterBlankValues(obj) {
      const filteredObj = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const { channel_name: channelName, channel_index: channelIndex } =
            obj[key];
          if (
            (channelName && channelName.trim() !== "") ||
            (channelIndex && channelIndex.trim() !== "")
          ) {
            filteredObj[key] = {
              ...(channelName && { channel_name: channelName }),
              ...(channelIndex && { channel_index: channelIndex }),
            };
            if (key === "position") {
              positionNames.forEach((pn) => {
                const positionValue = event.target[`${pn}Position`].value;
                if (positionValue) {
                  filteredObj[key][`${pn}_value`] = Number(positionValue);
                }
              });
            }
          }
        }
      }
      return filteredObj;
    }

    const isNullOrNumber = (value) =>
      value === null || value === undefined || !isNaN(value);
    const allValuesValid =
      channelIndexes.every(isNullOrNumber) && positions.every(isNullOrNumber);

    if (allValuesValid) {
      const requestBody = { name: channelMappingName };

      channelNames.forEach((cn) => {
        requestBody[cn] = {
          channel_name: event.target[`${cn}_channel_name`].value,
          channel_index: event.target[`${cn}_channel_index`].value,
        };
      });

      positionNames.forEach((pn) => {
        requestBody.position[`${pn}_value`] =
          event.target[`${pn}Position`].value;
      });

      const filteredRequestBody = filterBlankValues(requestBody);

      apiRequest("channelmapping/", {
        method: "POST",
        body: JSON.stringify({
          name: channelMappingName,
          ...filteredRequestBody,
        }),
      })
        .then((response) => {
          if (response.status === 403) {
            toast.error(
              "You don't have permission to create a channel mapping.",
            );
          } else if (response.ok) {
            // Handle success
            toast.success("Channel Mapping Successfully created!");
            navigate("/channelmappings");
          } else {
            // Handle other errors
            console.log(response);
            toast.error(
              "An error occurred while creating the channel mapping.",
            );
          }
        })
        .catch((error) => {
          toast.error("An error occured while creating the channel mapping");
          console.error(error);
        });
    } else {
      toast.error("All Index values must be either blank or a number.");
    }
  };

  return (
    <Form className="flex flex-wrap" onSubmit={createNewChannelMapping}>
      <Stack gap={4} className="body">
        <h1>Create New Channel Mapping</h1>
        <div className="data-card">
          <Table size="sm" responsive borderless className="mb-0">
            <tbody>
              <Form.Group as="tr" controlId="channelMappingName">
                <Col as="td" xs={4} md={3} lg={2}>
                  <Form.Label className="data-label">Name</Form.Label>
                </Col>
                <Col as="td">
                  <Form.Control type="text" name="channelMappingName" />
                </Col>
              </Form.Group>
            </tbody>
          </Table>
        </div>
        <div className="data-card">
          <Table size="sm" responsive>
            <thead>
              <tr>
                <Col as="th" xs={4} md={3} lg={2}>
                  Channel Type
                </Col>
                <Col as="th" xs={1}>
                  EDF Channel Index
                </Col>
                <Col as="th">EDF Channel Name</Col>
              </tr>
            </thead>
            <tbody>
              {channelNames.map((cn) => (
                <tr key={cn}>
                  <Col as="td" xs={4} md={3} lg={2}>
                    {cn}
                  </Col>
                  <Col as="td" xs={1}>
                    <Form.Group controlId={`${cn}_channel_index`}>
                      <Form.Control type="text" name={`${cn}_channel_index`} />
                    </Form.Group>
                  </Col>
                  <Col as="td">
                    <Form.Group controlId={`${cn}_channel_name`}>
                      <Form.Control type="text" name={`${cn}_channel_name`} />
                    </Form.Group>
                  </Col>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <h2>Positions</h2>
        <div className="data-card">
          <PositionTable form />
        </div>
        <Button variant="secondary" type="submit">
          Submit
        </Button>
      </Stack>
    </Form>
  );
};

export default NewChannelMapping;
