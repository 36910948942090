import { useEffect, useState } from "react";
import { Col, Stack, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { apiRequest } from "src/apiRequest";
import PageLoading from "src/components/PageLoading";

const CompaniesShow = () => {
  const { id } = useParams();
  const [company, setCompany] = useState();
  const [isLoadingCompany, setIsLoadingCompany] = useState(true);

  const getCompany = () => {
    apiRequest(`company/${id}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCompany(data);
        setIsLoadingCompany(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  if (isLoadingCompany) {
    return <PageLoading />;
  }

  if (!isLoadingCompany && !company) {
    return <div>Company not found</div>;
  }

  return (
    <Stack gap={4} className="body">
      <h1>Company ID: {company.id}</h1>
      <div className="flex flex-wrap justify-content-between align-items-start">
        <Col xs={12} className="data-card">
          <Table size="sm" borderless>
            <tr>
              <th scope="row">Name:</th>
              <td>{company.name}</td>
            </tr>
            <tr>
              <th scope="row">Active:</th>
              <td>{company.is_active ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <th scope="row">Created At:</th>
              <td>{company.date_created}</td>
            </tr>
            <tr>
              <th scope="row">Description:</th>
              <td>{company.description}</td>
            </tr>
          </Table>
        </Col>
      </div>
    </Stack>
  );
};

export default CompaniesShow;
