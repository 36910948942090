import { Button, Form, InputGroup, Modal } from "react-bootstrap";

const VerificationCodeModal = ({ show, handleVerify }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    handleVerify(event.target.verificationCode.value);
  };

  return (
    <Modal show={show} backdrop="static" keyboard={false} size="sm">
      <Modal.Header>
        <Modal.Title>Enter Verification Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <Form.Control
              type="text"
              name="verificationCode"
              ref={(input) => input && input.focus()}
            />
            <Button type="submit">Verify</Button>
          </InputGroup>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default VerificationCodeModal;
