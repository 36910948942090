import { useState } from "react";
import { Alert, Button, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import "src/assets/Login.scss";
import Modal from "src/components/Modal";
import VerificationCodeModal from "src/components/VerificationCodeModal";
import {
  loginWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
} from "src/firebase";

function Login({ logoutMessage }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [signupName, setSignupName] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [verificationCallback, setVerificationCallback] = useState(null);

  const showResetPasswordHandler = () => {
    setShowResetPassword(true);
  };

  const hideResetPasswordHandler = () => {
    setShowResetPassword(false);
  };

  const sendResetPasswordHandler = () => {
    sendPasswordReset(resetEmail);
    setShowResetPassword(false);
  };

  const hideSignupHandler = () => {
    setShowSignup(false);
  };

  const sendRegistrationHandler = () => {
    // add the password validation here?
    if (signupPassword.length >= 6) {
      registerWithEmailAndPassword(signupName, signupEmail, signupPassword);
      setShowSignup(false);
    } else {
      toast.error("Password must be at least 6 characters");
    }
  };

  const handleLogin = () => {
    loginWithEmailAndPassword(email, password, (callback) => {
      setVerificationCallback(() => callback);
      setShowVerify(true);
    });
  };

  const handleVerify = (code) => {
    if (verificationCallback) {
      verificationCallback(code);
      setVerificationCallback(null);
    }
    setShowVerify(false);
  };

  return (
    <div className="login">
      <div className="login-container">
        <Stack gap={2}>
          <input
            type="text"
            className="login-textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail Address"
          />
          <input
            type="password"
            className="login-textBox"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          {logoutMessage && <Alert variant="danger">{logoutMessage}</Alert>}
        </Stack>
      </div>
      <Button className="login-btn login-google" onClick={handleLogin}>
        Login
      </Button>
      <div className="flex justify-content-between">
        <Link onClick={showResetPasswordHandler}>Forgot Password?</Link>
        {/* <p>Need an Account? <Link onClick={showSignupHandler}>Sign up Here</Link></p> */}
      </div>
      {showSignup && (
        <Modal onClose={hideSignupHandler}>
          <div className="flex flex-column align-items-center">
            <h3>Sign up!</h3>
            <Stack gap={3}>
              <input
                type="text"
                className="login-textBox col-6"
                placeholder="Name"
                onChange={(e) => setSignupName(e.target.value)}
              />
              <input
                type="text"
                className="login-textBox col-6"
                placeholder="Email"
                onChange={(e) => setSignupEmail(e.target.value)}
              />
              <input
                type="password"
                className="login-textBox col-6"
                placeholder="Password"
                onChange={(e) => setSignupPassword(e.target.value)}
              />
            </Stack>
            <div className="Button-row">
              <Button onClick={sendRegistrationHandler}>Continue</Button>
              <Button variant="outline-secondary" onClick={hideSignupHandler}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {showResetPassword && (
        <Modal onClose={hideResetPasswordHandler}>
          <Stack gap={3} className="flex flex-column align-items-center">
            <h3>Forgot your password?</h3>
            <input
              type="text"
              className="login-textBox"
              placeholder="Email"
              onChange={(e) => setResetEmail(e.target.value)}
            />
            <div className="button-row">
              <Button onClick={sendResetPasswordHandler}>Reset Password</Button>
              <Button
                variant="outline-secondary"
                onClick={hideResetPasswordHandler}
              >
                Cancel
              </Button>
            </div>
          </Stack>
        </Modal>
      )}
      <VerificationCodeModal show={showVerify} handleVerify={handleVerify} />
    </div>
  );
}

export default Login;
