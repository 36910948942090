import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";

import cerebraSleepSystemLogo from "src/assets/images/cerebra_sleep_system_logo.png";
import "src/assets/Navbar.scss";
import { logout } from "src/firebase";
import { useUser } from "src/UserContext";

const NavBar = () => {
  const { firebaseUser } = useUser();

  return (
    <div className="nav-container">
      <div className="navbar-topbar">
        <Navbar.Brand href="/">
          <img
            alt="logo"
            src={cerebraSleepSystemLogo}
            style={{
              height: "36px",
            }}
          />
        </Navbar.Brand>
        <Nav className="d-none d-md-block" navbar>
          {firebaseUser && (
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} id="profileDropDown">
                <FontAwesomeIcon className="user-icon" icon="user-circle" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="profile-dropdown">
                <Dropdown.Header>{firebaseUser.email}</Dropdown.Header>
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                <Dropdown.Item href="/releasenotes">
                  Release Notes
                </Dropdown.Item>
                <Dropdown.Item href="/manual.pdf">Manual</Dropdown.Item>
                <Dropdown.Item href="https://docs.cerebraportal.com/">
                  API Docs
                </Dropdown.Item>
                <Dropdown.Item id="qsLogoutBtn" onClick={() => logout()}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Nav>
      </div>
      <Navbar expand="lg">
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            {firebaseUser && (
              <NavDropdown title="Setup">
                <NavDropdown.Item href="/studies">Studies</NavDropdown.Item>
                <NavDropdown.Item href="/users">Users</NavDropdown.Item>
                <NavDropdown.Item href="/sites">Sites</NavDropdown.Item>
                <NavDropdown.Item href="/companies">Companies</NavDropdown.Item>
                <NavDropdown.Item href="/channelmappings">
                  Channel Mappings
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
