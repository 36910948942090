import { Button, Stack } from "react-bootstrap";

import ScoringRunTable from "src/components/ScoringRuns";

function Dashboard() {
  return (
    <Stack gap={4} className="body">
      <div className="text-center">
        <Button variant="secondary" href="/wizard" size="lg">
          New File Wizard
        </Button>
      </div>
      <ScoringRunTable />
    </Stack>
  );
}

export default Dashboard;
