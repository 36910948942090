import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Stack, Tooltip } from "react-bootstrap";

const TextWithTooltip = ({ text, tooltip }) => {
  return (
    <Stack direction="horizontal" gap={2}>
      {text}
      <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
        <span>
          <FontAwesomeIcon icon="question-circle" />
        </span>
      </OverlayTrigger>
    </Stack>
  );
};

export default TextWithTooltip;
