export function getConfig() {
  // return config values specified in the .env file
  return {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    apiHostname: process.env.REACT_APP_API_HOSTNAME,
    getFileRetryTimeout_ms:
      parseInt(process.env.REACT_APP_GET_FILE_RETRY_TIMEOUT_MS) || 5000,
    getFileRetryAttempts:
      parseInt(process.env.REACT_APP_GET_FILE_RETRY_ATTEMPTS) || 12,
  };
}
